import { z } from 'zod';
import { durationEnum } from '~/schemas/enums';
import { isoDateSchema } from './custom-schemas';
import { carbonContentEnum, matchingResolutionEnumLower } from './enums';
import { valueUnitMetricSchema } from './shared';

const period = z.object({
  start_time: isoDateSchema,
  end_time: isoDateSchema,
});

const tags = z.record(z.string(), z.array(z.string().nullable()));

// Consumer and Supply latest response
export const latestPeriodResponse = z.object({
  month: period,
});

export type LatestPeriod = z.infer<typeof latestPeriodResponse>;

// Carbon Request
export const consumerCarbonRequestSchema = z.object({
  start_datetime: z.string(),
  end_datetime: z.string(),
  consumer_ids: z.array(z.string()).optional().nullable(),
  matching_resolutions: z.array(matchingResolutionEnumLower).nullable(),
  tags: tags.optional().nullable(),
});

export type ConsumerCarbonRequest = z.infer<typeof consumerCarbonRequestSchema>;

// Carbon Response Schemas
export const carbonMetricSchema = z.object({
  location_based: z.number().nullable(),
  market_based: z.number().nullable(),
  impact: z.number().nullable(),
  unit: carbonContentEnum.nullable(),
});

const carbonMetricsAtMatchingResolutionSchema = z.object({
  matching_resolution: matchingResolutionEnumLower,
  metrics: carbonMetricSchema,
});

export const consumerCarbonMetricsResponseSchema = z.array(carbonMetricsAtMatchingResolutionSchema);
export type ConsumerCarbonResponse = z.infer<typeof consumerCarbonMetricsResponseSchema>;

// Consumer overview request
export const consumerOverviewRequestSchema = z.object({
  start_datetime: z.string(),
  end_datetime: z.string(),
  consumer_ids: z.array(z.string()).optional().nullable(),
  matching_resolutions: z.array(matchingResolutionEnumLower).nullable(),
  tags: tags.optional().nullable(),
});

export type ConsumerOverviewRequest = z.infer<typeof consumerOverviewRequestSchema>;

// Consumer overview response
export const consumerOverviewSchema = z.object({
  consumption: valueUnitMetricSchema,
  current: z.array(
    z.object({
      excess_generation_allocated: valueUnitMetricSchema,
      matching_generation_allocated: valueUnitMetricSchema,
      matching_generation_allocated_percentage: z.number(),
      matching_resolution: matchingResolutionEnumLower,
      total_generation_allocated: valueUnitMetricSchema,
      unmatched_consumption: valueUnitMetricSchema,
    }),
  ),
});

export type ConsumerOverview = z.infer<typeof consumerOverviewSchema>;

// Consumer matching resolutions request
export const consumerMatchingResolutionsRequestSchema = z.object({
  start_datetime: z.string(),
  end_datetime: z.string(),
  consumer_ids: z.array(z.string()).optional().nullable(),
  tags: tags.optional().nullable(),
});

export type ConsumerMatchingResolutionsRequest = z.infer<typeof consumerMatchingResolutionsRequestSchema>;

// Consumer matching resolutions response
export const consumerMatchingResolutionsResponseSchema = z.object({
  matching_resolutions: z.array(matchingResolutionEnumLower),
});

export type ConsumerMatchingResolutionsResponse = z.infer<typeof consumerMatchingResolutionsResponseSchema>;

export const perimeterProductionDevicesSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  country: z.string(),
  technology: z.string(),
  durations: z.array(durationEnum),
});

export type PerimeterProductionDevice = z.infer<typeof perimeterProductionDevicesSchema>;

export const perimeterConsumersSchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  country: z.string(),
  production_devices_with_allocated_production: z.array(z.string()),
  durations: z.array(durationEnum),
});

export type PerimeterConsumer = z.infer<typeof perimeterConsumersSchema>;

export const perimeterSchema = z.object({
  start_datetime: isoDateSchema,
  end_datetime: isoDateSchema,
  durations: z.array(durationEnum),
  consumers_with_consumption: z.array(perimeterConsumersSchema),
  production_devices_with_production: z.array(perimeterProductionDevicesSchema),
});

export type PerimeterType = z.infer<typeof perimeterSchema>;
