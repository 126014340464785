import { z } from 'zod';
import { bigNumberSchema } from '~/schemas/custom-schemas';
import { createLiteralUnion } from '~/utils/zod';

export const filterTypes = z.union([z.literal('between'), z.literal('one_of'), z.literal('less_than_or_equal_to')]);
export const aggregateFunctions = z.union([z.literal('sum'), z.literal('avg'), z.literal('min'), z.literal('max')]);

// Filter schemas
const periodStartFilter = z.object({
  type: filterTypes,
  from: z.string().datetime(), // Inclusive
  to: z.string().datetime(), // Exclusive
});

const stringArrayFilter = z.object({
  type: filterTypes,
  items: z.array(z.string().nullable()),
});

const stringValueFilter = z.object({
  type: filterTypes,
  value: z.string(),
});

// Pagination schema

const pagination = z.object({
  skip: z.number().int().positive().optional(),
  limit: z.number().int().positive().optional(),
});

// Supply table schemas
const supplyTableLabel = z.literal('kpiTotals');
const supplyColumns = ['supply', 'supply_allocated', 'supply_unallocated'] as const;

const supplyTableRequest = z.object({
  views: z.array(
    z.object({
      label: supplyTableLabel,
      metrics: z.array(
        z.object({
          column: createLiteralUnion(supplyColumns),
          aggregation_function: aggregateFunctions,
        }),
      ),
    }),
  ),
});

const supplyTableResponse = z.object({
  views: z.array(
    z.object({
      label: supplyTableLabel,
      columns: z.array(z.object({ column: z.enum(supplyColumns) })),
      data: z.array(z.tuple([bigNumberSchema, bigNumberSchema, bigNumberSchema])),
    }),
  ),
});

// Allocated supply schemas
const allocatedSupplyTableLabel = z.literal('totalsByAllocatedConsumer');
const allocatedSupplyGroups = ['allocated_consumer_client_reference', 'allocated_consumer_name'] as const;
const allocatedSupplyMetrics = ['supply_allocated'] as const;

const allocatedSupplyTableRequest = z.object({
  views: z.array(
    z.object({
      label: allocatedSupplyTableLabel,
      pagination: pagination,
      groups: z.array(
        z.object({
          column: createLiteralUnion(allocatedSupplyGroups),
          limit: z.number().int().positive().optional(),
        }),
      ),
      metrics: z.array(
        z.object({
          column: z.literal(allocatedSupplyMetrics[0]),
          aggregation_function: aggregateFunctions,
        }),
      ),
    }),
  ),
});

const allocatedSupplyTableResponse = z.object({
  views: z.array(
    z.object({
      label: allocatedSupplyTableLabel,
      columns: z.array(z.object({ column: z.enum([...allocatedSupplyMetrics, ...allocatedSupplyGroups]) })),
      data: z.array(z.tuple([z.string(), z.string(), bigNumberSchema])),
    }),
  ),
});

// POST /metrics/supply

// TODO: create a function to create the schema for the requests and responses
// These will then need to be passed into the query hooks to validate the responses

// Request body
export const supplyRequestSchema = z.object({
  filters: z.object({
    period_start: periodStartFilter,
    production_device_client_reference: stringArrayFilter.optional(),
    production_device_technology: stringArrayFilter.optional(),
    production_device_subsidy_regime: stringArrayFilter.optional(),
    production_device_registry_identifier: stringArrayFilter.optional(),
    supply_duration: stringValueFilter.optional(),
  }),
  tables: z.object({
    supply: supplyTableRequest,
    allocated_supply: allocatedSupplyTableRequest,
  }),
});

export type SupplyRequest = z.infer<typeof supplyRequestSchema>;

// Response body
export const supplyResponseSchema = z.object({
  tables: z.object({
    supply: supplyTableResponse,
    allocated_supply: allocatedSupplyTableResponse,
  }),
});

export type SupplyResponse = z.infer<typeof supplyResponseSchema>;
